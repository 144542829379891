import './App.css';

function App() {
  return (
    <div className='container'>
      <div className='coming'>
        <h2> Coming soon.... </h2>
      </div>
    </div>
  );
}

export default App;
